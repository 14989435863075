import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Img from 'gatsby-image'

const Wrap = styled.div`
  ${tw`md:flex md:flex-wrap md:px-10 mx-auto`}
  max-width: 1400px;

  a {
    ${tw`block md:w-1/2 lg:w-1/3 mb-10 hover:opacity-75
    transition-opacity duration-300 ease-in-out`}

    .gatsby-image-wrapper {
      ${tw`mx-5`}
    }
  }
`

const Title = styled.div`
  ${tw`text-left px-5`}
  margin-top: 1rem;
`

const WorkListing = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicWork {
        nodes {
          uid
          data {
            title
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700, maxHeight: 465, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrap>
      {data.allPrismicWork.nodes.map((project, key) => (
        <Link to={`/work/${project.uid}`} title={project.data.title} key={key}>
          <Img
            fluid={project.data.thumbnail.localFile.childImageSharp.fluid}
            alt={project.data.title}
          />
          <Title>{project.data.title}</Title>
        </Link>
      ))}
    </Wrap>
  )
}

export default WorkListing
