import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WorkListing from '../components/WorkListing'

const PageTitle = styled.h1`
  ${tw`mb-10 text-center`}
`

const WorkPage = () => (
  <Layout>
    <SEO title="Work" />
    <PageTitle>Work</PageTitle>
    <WorkListing />
  </Layout>
)

export default WorkPage
